import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"

const ThanksContact = () => {
  return (
    <Layout lang="zh-CN">
      <Seo
        pagetitle="查询完毕"
        pagepath="/thanks-contact"
        pagedesc="查询完毕。 | PARKLoT提供了一个Twitter活动工具，可以 随心所欲 地运行，只需支付 月费。"
      />
      <section className="thanks">
        <div className="l-container">
          <h3 className="thanks__title">谢谢你的询问。</h3>
          <p className="thanks__subtitle">
            我们将在检查您的询问后的三个工作日内与您联系。
            <br />
            同时，如果你愿意，你可以看一下我们的常见问题。
          </p>
          <hr className="thanks__hr" />
          <div className="qa-list__box">
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                在这个价格下，你到底能开展多少次活动？ 是否有任何额外的费用？
              </dt>
              <dd className="qa-list__data">
                如果你注册了一个固定价格的计划，你可以在没有额外费用的情况下开展任意数量的活动。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">开始运动的过程是什么？</dt>
              <dd className="qa-list__data">
                基本流程是：申请--数据准备和支付--活动创建--活动开始。
              </dd>
              <dd className="qa-list__detail">
                <a href="/qa/starting-a-campaign" className="qa-list__link">
                  了解更多
                </a>
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                我是否可以在申请前检查管理屏幕的实际操作和演示屏幕上的参与者的线索等？
              </dt>
              <dd className="qa-list__data">
                是的，这是有可能的。 欲了解更多信息，请联系。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                我是否需要遵循任何取消的程序？
              </dt>
              <dd className="qa-list__data">不是特别需要。</dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                可以收集的转发用户数据的最大数量是多少？
              </dt>
              <dd className="qa-list__data">
                每天可以收集超过100,000个案例。
                如果你需要收集更多，请联系你的联系人。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                在多大程度上可以获得有关参与活动的Twtter账户的信息？
              </dt>
              <dd className="qa-list__data">
                基本上可以收集到Twitter上公开的资料数据。
                个人账户上被设置为隐私的信息不能被收集。
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                是否有任何机制可以联系到参与活动但未被抽中的用户？
              </dt>
              <dd className="qa-list__data">
                是的，有。
                宣传活动可以设计成将人们引导到LINE、Instagram或他们自己的网站。
                <br />
              </dd>
            </dl>
            <dl className="qa-list__list">
              <dt className="qa-list__question">
                你能协助我获得一个认证徽章吗？
              </dt>
              <dd className="qa-list__data">
                是的，你可以。 欲了解更多信息，请联系。
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ThanksContact
